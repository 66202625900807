<template lang="html">
  <div
    class="ScrollUp"
    :class="{ showScroller: showScroller }"
    title="Scroll up"
    @click="scrollTop"
  >
    <img
      src="@/assets/img/icons/ArrowUp.png"
      alt="Scroll up!"
      width="60"
      height="60"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showScroller: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollTop() {
      const html = document.getElementsByTagName("html");
      html[0].style.scrollBehavior = "smooth";
      setTimeout(this.scroll, 100);
    },
    scroll() {
      window.scrollTo(0, window.innerHeight - 36);
      setTimeout(this.resetScrollBehavior, 500);
    },
    resetScrollBehavior() {
      const html = document.getElementsByTagName("html");
      html[0].style.scrollBehavior = "auto";
    },
    handleScroll() {
      // var body = document.body,
      //   html = document.documentElement;

      if (window.scrollY > window.innerHeight + window.innerHeight / 4) {
        this.showScroller = true;
      } else {
        this.showScroller = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.ScrollUp {
  display: none;
}

@media only screen and (min-width: 768px) {
  .ScrollUp {
    display: block;
    position: fixed;
    bottom: calc(50vh - 30px);
    right: 24px;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    transition: opacity 1s;
    user-select: none;
  }

  .showScroller {
    opacity: 1;
  }

  .ScrollUp img {
    width: 60px;
    height: 60px;
  }

  .smoothScroll {
    scroll-behavior: smooth;
  }
}

@media only screen and (min-width: 900px) {
  .ScrollUp {
    right: 36px;
  }
}
</style>
