<template lang="html">
  <div class="Trigger">
    <img
      v-if="color"
      src="@/assets/img/icons/MenuTriggerWhite.png"
      alt="Trigger Menu"
      @click="toggleNavigation()"
      width="40"
      height="40"
    />
    <img
      v-else
      alt="Trigger Menu"
      src="@/assets/img/icons/MenuTriggerBlack.png"
      @click="toggleNavigation()"
      width="40"
      height="40"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    color: null,
  },
  computed: {
    ...mapGetters(['showNavigation']),
  },
  methods: {
    toggleNavigation() {
      this.$store.state.showNavigation = !this.$store.state.showNavigation;
    },
  },
};
</script>

<style lang="css" scoped>
.Trigger {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  position: relative;
  top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  width: 40px;
}
</style>
