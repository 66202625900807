import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
const app = createApp({ render: () => h(App) });

// VUE META
//import VueMeta from "vue-meta";
// Vue.use(VueMeta, {
//   refreshOnceOnNavigation: true,
// });

//Vue.config.productionTip = false;

// FETCH ROUTES
let req = new FormData();
req.append("o", "is_order");
req.append("l", -1);
axios
  .post("/api/routes/", req)
  .then((res) => {
    store.state.pageData = null;
    store.commit("setPageRoutes", res.data.content);
    for (const page of res.data.content) {
      var newRoute = {
        name: page.route_name,
        path: page.route,
        component: () => import("./views/View" + page.route_view),
        pathToRegexpOptions: { strict: true },
        props: {
          id: page.id,
        },
        meta: {
          title: process.env.VUE_APP_TITLE + " - " + page.title,
        },
      };

      if (page.route == "*") newRoute.path = "/";

      if (page.route) router.addRoute(newRoute);
    }

    init();
  })
  .catch((err) => {
    console.log(err);
  });

// INIT APP AFTER ROUTE FETCH
function init() {
  app.use(router).use(store).mount("#app");
}
