<template lang="html">
  <div
    class="CookieDisclaimer"
    :class="{ hide: cookieSet }"
    style="position:fixed"
  >
    <div class="text">
      This website uses cookies to allow the website to operate properly and for
      us to track website usage. By using this website, you agree that the
      website may place cookies on your device. Please see our
      <router-link :to="{ name: 'privacy', params: {} }">
        Privacy Policy
      </router-link>
      for more information.
    </div>
    <button @click="close">Close</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookieSet: false,
    };
  },
  methods: {
    close() {
      this.cookieSet = true;

      setTimeout(() => {
        this.setCookie('disclaimer', true, 60);
      }, 500);
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = 'expires=' + d.toUTCString();
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    getCookie(cname) {
      var name = cname + '=';
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
  },
  created() {
    this.cookieSet = this.getCookie('disclaimer');
  },
};
</script>

<style lang="css" scoped>
.CookieDisclaimer {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #222929;
  color: white;
  z-index: 99999999999999999999;
  padding: 12px;
  user-select: none;
  width: calc(100% - 24px);
  transition: transform 1s;
}

.CookieDisclaimer a {
  color: white;
  padding-bottom: 2px;
  border-bottom: 1px solid white;
}

.hide {
  transform: translateY(100%);
}

button {
  background: #222929;
  color: white;
  padding: 7px;
  margin-top: 12px;
  border: 1px solid white;
  outline: none;
  margin-right: 6px;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .CookieDisclaimer {
    padding: 24px;
    width: calc(100% - 48px);
  }

  button {
    margin-top: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .CookieDisclaimer {
    padding: 24px 36px;
    width: calc(100% - 60px);
  }
}
</style>
